import { useNode } from '@craftjs/core';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';

import Resizer from '../Resizer';

import VimeoVideoSettings from './VimeoVideoSettings';

const VimeoDiv = styled.div<any>`
  width: 100%;
  height: 100%;
  z-index: 9999;
  > div {
    height: 100%;
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
    pointer-events: ${(props) => (props.$enabled ? 'none' : 'auto')};
  }
`;

type VimeoVideoProps = {
  vimeoURL: string;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  height: string;
  padding: string[];
  margin: string[];
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
};

const defaultProps = {
  vimeoURL: '',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  width: '100%',
  height: '100%',
};

function VimeoVideo(props: Partial<VimeoVideoProps>) {
  const {
    connectors: { connect },
  } = useNode();

  props = {
    ...defaultProps,
    ...props,
  };

  const { vimeoURL, flexDirection, alignItems, justifyContent, padding, margin } = props;

  return (
    <Resizer
      ref={connect}
      className="VimeoVideo"
      propKey={{ width: 'width', height: 'height' }}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
      }}
    >
      <VimeoDiv>{vimeoURL && <Vimeo video={vimeoURL} height="100%" width="100%" />}</VimeoDiv>
    </Resizer>
  );
}

VimeoVideo.craft = {
  displayName: 'Vimeo Video',
  props: defaultProps,
  related: {
    toolbar: VimeoVideoSettings,
  },
};

export type { VimeoVideo };
export default VimeoVideo;
