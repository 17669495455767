import React, { useEffect, useState } from 'react';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';
import config from '../../../config';
import { Button } from 'reactstrap';
import { AlpLogo } from '../../../helpers/imagePreloader';
import {
  userContextApi,
  useGetUserContext,
  useLazyGetUserContextQuery,
  useLazyGetUserLatestSubscriptionQuery,
} from '../../../store/services/userContextApi';
import cookie from '../../../helpers/cookieHelper';
import UserCard from '../UserCard/UserCard';
import { getTopmenuItems } from '../Utils/utilityFunctions';
import Image from '../../../components/Image/Image';
import ResourceSearchInput from '@components/ResourceSearchInput/ResourceSearchInput';

function Navigation(props: any) {
  const link = document.getElementById('homeID');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if ((link as any)?.href === window.location.href) {
      (link as any).classList.add('isActiveBtn');
    } else {
      (link as any)?.classList?.remove('isActiveBtn');
    }
  }, [link, window.location.href]);
  const { data: userData } = useGetUserContext('');
  // @ts-ignore
  userContextApi.endpoints.getUserLatestSubscription.useQueryState('', {
    selectFromResult: (result: any) => result,
  });
  const [getUserLatestSubscriptionQuery] = useLazyGetUserLatestSubscriptionQuery();
  const [getUserContext] = useLazyGetUserContextQuery();
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [menuItems, setMenuItems] = useState<any[]>(getTopmenuItems(props.is_subscribed));
  const [dropdownOpened, setDropdownOpened] = useState('');

  // @TODO: This is temporary only. revert these changes once everything is stable
  const menus = (): any[] => {
    return (
      menuItems
        ? menuItems.map((item) => {
            if (userData) {
              if (
                userData.roles.length > 0 &&
                userData.roles.find((r: any) => r.role.short_code == 'FREESUB') &&
                item.displayName == 'My Account'
              )
                return null;
            }
            return item;
          })
        : []
    ).filter((m) => m);
  };

  const toggleMenu = (e: any) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (cookie.isLoggedIn()) getUserContext('');
  }, []);

  useEffect(() => {
    const user = userData as any;
    if (user) {
      let userName = '';
      if (user.is_institution) {
        userName = user.institution_profile ? user.institution_profile?.institution_name : '';
      } else {
        userName = user.user_profile ? `${user.user_profile?.first_name} ${user.user_profile?.last_name}` : '';
      }

      userName.trim() !== '' ? setName(userName) : setName(user.email);
      setUserId(user?.id);
      getUserLatestSubscriptionQuery('');
    }
  }, [userData]);

  useEffect(() => {
    setMenuItems(getTopmenuItems(cookie.isLoggedIn() && userData && userData.licenses.length > 0));
  }, [cookie.isLoggedIn(), userData]);

  const getCarretIcon = (dropdownId: string) => {
    return dropdownOpened === dropdownId ? (
      <i className="icon icon-chevron-up nav-dropdown-icon" />
    ) : (
      <i className="icon icon-chevron-down nav-dropdown-icon" />
    );
  };

  const getMenu = (startSlice: number, endSlice?: number): JSX.Element[] => {
    return menus()
      .slice(startSlice, endSlice)
      .map((item: any, key: number) => {
        if ((!item.isProtected && !cookie.isLoggedIn()) || (!item.hideAfterLogin && cookie.isLoggedIn())) {
          return (
            <li
              key={key}
              className={item.displayName.length > 40 ? 'w-shrink' : item.displayName.length > 22 ? 'w-shrink-2' : 'no-shrink' + (item.className ?? '')}
            >
              {!item.dropdownId ? (
                <NavLink
                  to={item.urlPath}
                  target={item.linkToNewTab ? '_blank' : ''}
                  onClick={() => setDropdownOpened('')}
                  className={item.className}
                  end
                >
                   <span dangerouslySetInnerHTML={{ __html: item.displayName }} />
                  <div className="selected-underline" />
                </NavLink>
              ) : (
                // if the menu is a dropdown, we use div instead of navlink to prevent redirect and just show the submenu
                <div
                  className="main-menu-dropdownmenu"
                  onClick={() => setDropdownOpened((state) => (state === item.dropdownId ? '' : item.dropdownId))}
                >
                  <span>{item.displayName}</span>

                  {getCarretIcon(item.dropdownId)}
                </div>
              )}

              <div className="selected-underline" />
            </li>
          );
        } else {
          return <React.Fragment key={key} />;
        }
      });
  };

  return (
    <>
      <header className={`main-header flex flex-col ${menuOpen ? 'open' : ''}`}>
        <div className={`navigation-row`}>
          <nav className="flex flex-row ">
            <div className="brand-col-wrapper flex flex-row">
              <div className="brand-col">
                <a href="/">
                  <Image src={AlpLogo} alt="A Learning Place Logo" />
                </a>
              </div>
              <a href='#' className="hamburger" onClick={toggleMenu}>
                &#9776;
              </a>
              <div className="flex flex-row flex_1 gap1">
                <div className="flex flex-row flex_1">
                  <ul className={`menu-col flex flex-row ${menuOpen ? 'open' : ''}`}>
                    {getMenu(0, 6)}
                  </ul>
                </div>
                <div className='ml-auto flex flex-row'>
                  <ResourceSearchInput isAuth={!(!userData)} />
                  <div className="buttons-col flex flex-row">
                    {cookie.isLoggedIn() ? (
                      <UserCard name={name} isInstitution={userData?.is_institution} userId={userId} />
                    ) : (
                      <>
                        <a href={`${config.subsitesUrl.main}/login/redirect/${config.subsiteCode}`}>
                          <Button color="link" className="login-btn">
                            LOGIN
                          </Button>
                        </a>

                        <a href={`${config.subsitesUrl.main}/createaccount`}>
                          <Button className="create-btn">CREATE AN ACCOUNT TO<br/>SUBSCRIBE TO A RESOURCE</Button>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className={`subsite-row flex with-shadow`}></div>
      </header>
    </>
  );
}

export default Navigation;
