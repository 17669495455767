import { FormControl, InputLabel, Select } from '@mui/material';

function ToolbarDropdown({ title, value, onChange, children }: any) {
  return (
    <FormControl>
      <InputLabel>{title}</InputLabel>
      <Select native value={value} onChange={(e) => onChange(e.target.value)}>
        {children}
      </Select>
    </FormControl>
  );
}

export default ToolbarDropdown;
