import config from './index';

export default [
  {
    name: 'Teaching Resources',
    url: config.subsitesUrl.tr,
    color: '#2B8080', //GREEN
    faded_color: '#96C0C0', //GREEN
    short_code: 'TR',
    icon_name: 'subsite-tr',
  },
  {
    name: 'Student Learning Resources',
    url: config.subsitesUrl.slr,
    color: '#4950DF', //BLUE
    faded_color: '#B4B7F2', //BLUE
    short_code: 'SLR',
    icon_name: 'subsite-slr',
  },
  {
    name: 'Student Tracking & Reporting',
    url: config.subsitesUrl.slr,
    color: '#912B91', //PURPLE
    faded_color: '#C896C8', //PURPLE
    short_code: '4TD',
    icon_name: 'subsite-str',
  },
  {
    name: 'Digital and Virtual Resources',
    url: config.subsitesUrl.dvr,
    color: '#F89A3C', //YELLOW
    faded_color: '#FCD6AF', //YELLOW
    short_code: 'DVR',
    icon_name: 'subsite-dvr',
  },
  {
    name: '4-Tier Diagnostic Assessment',
    url: config.subsitesUrl.ftd,
    color: '#AA2B56', //RED
    faded_color: '#D596AB', //RED
    short_code: '4TD',
    icon_name: 'subsite-4td',
  },

  // original lower 3
  // {
  //   name: '4-Tier Diagnostic Assessment',
  //   url: config.subsitesUrl.ftd,
  //   color: '#912B91', //PURPLE
  //   faded_color: '#C896C8', //PURPLE
  //   short_code: '4TD',
  //   icon_name: 'subsite-4td',
  // },
  // {
  //   name: 'Professional Learning Resources',
  //   url: config.subsitesUrl.plr,
  //   color: '#AA2B56', //RED
  //   faded_color: '#D596AB', //RED
  //   short_code: 'PLR',
  //   icon_name: 'subsite-plr',
  // },
  // {
  //   name: 'Digital and Virtual Resources',
  //   url: config.subsitesUrl.dvr,
  //   color: '#F89A3C', //YELLOW
  //   faded_color: '#FCD6AF', //YELLOW
  //   short_code: 'DVR',
  //   icon_name: 'subsite-dvr',
  // },
];
