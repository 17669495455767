import { useEffect, Suspense, useState } from 'react';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';

import useRouterDom from '../../hooks/useRouterDom';
import { useLazyGetUserContextQuery } from '../../store/services/userContextApi';
import { useFindOnePDFFileQuery } from '../../features/PDFFileUpload/store/pdfFileApi';
import config from '../../config';

interface ViewPDFWrapperProps {
  children: JSX.Element;
}

function ViewPDFWrapper({ children }: ViewPDFWrapperProps) {
  const { searchParams } = useRouterDom();
  const [getUserContext] = useLazyGetUserContextQuery();
  const { data: pdfFileData, isSuccess: isPdfFileDataSuccess } = useFindOnePDFFileQuery({ filename: searchParams.get('name') });
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isPdfFileDataSuccess) {
        // check if its free or paid
        if (pdfFileData.access === 'Paid') {
          // check if its logged in. if not navigate to login
          const isLoggedIn = await getUserContext('');

          // not logged in navigate to login
          if (isLoggedIn.error) {
            window.location.href = `${config.subsitesUrl.main}/login`;
          } else {
            setDisplay(true);
          }
        }

        if (pdfFileData.access === 'Free') {
          setDisplay(true);
        }
      }
    };

    fetchData().catch(console.error);
  }, [isPdfFileDataSuccess]);

  return <Suspense fallback={<LoadingScreen />}>{display ? children : <></>}</Suspense>;
}

export default ViewPDFWrapper;
