import { createSlice } from '@reduxjs/toolkit';

interface TableStoreProps {
  sortBy: string;
  sortAscending: boolean;
  pagination: PaginationProps;
  pages: number[];
}

export interface PaginationProps {
  page: number;
  limit?: number;
  total_docs: number;
  total_pages: number;
}

const initialState: TableStoreProps = {
  sortBy: '',
  sortAscending: false,
  pagination: { page: 1, limit: 10, total_docs: 0, total_pages: 1 },
  pages: [],
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    // initializePageTable: {
    //   reducer: (state, action: any) => {
    //     state.sortBy = action.payload.defaultSortFieldId;
    //     state.sortAscending = action.payload.defaultSortAscending;
    //     state.pagination.page = initialState.pagination.page;
    //     state.pagination.limit = state.pagination.limit;
    //     state.pagination.total_docs = initialState.pagination.total_docs;
    //     state.pagination.total_pages = initialState.pagination.total_pages;
    //     state.pages = initialState.pages;
    //   },
    //   prepare: (defaultSortFieldId: string, defaultSortAscending: boolean): any => {
    //     return {
    //       payload: {
    //         defaultSortFieldId,
    //         defaultSortAscending,
    //       },
    //     };
    //   },
    // },
    setPagination: {
      reducer: (state, action: any) => {
        state.pagination.total_docs = action.payload.pagination.total_docs;
        state.pagination.total_pages = action.payload.pagination.total_pages;
        state.pages = action.payload.pages;
      },
      prepare: (pagination: PaginationProps): any => {
        return {
          payload: {
            pagination,
            pages: Array.from({ length: pagination.total_pages }, (v, k) => k + 1),
          },
        };
      },
    },
    setPageLimit: {
      reducer: (state, action: any) => {
        if (!action.payload.limit) {
          state.pagination.page = 1;
        }
        state.pagination.limit = action.payload.limit;
      },
      prepare: (limit?: Number): any => {
        return {
          payload: {
            limit,
          },
        };
      },
    },
    updateSorting: {
      reducer: (state, action: any) => {
        state.sortBy = action.payload.fieldId;
        state.sortAscending = action.payload.isAscending;
      },
      prepare: (fieldId: string, isAscending: boolean): any => {
        return {
          payload: {
            fieldId,
            isAscending,
          },
        };
      },
    },
    updateCurrentPage: {
      reducer: (state, action: any) => {
        state.pagination.page = action.payload.pagenum;
      },
      prepare: (pagenum: number): any => {
        return {
          payload: {
            pagenum,
          },
        };
      },
    },
    updateTotalPages: {
      reducer: (state, action: any) => {
        state.pagination.total_docs = action.payload.totalRecords;
        state.pagination.total_pages = action.payload.totalPages;
        state.pages = action.payload.pages;
      },
      prepare: (totalRecords: number, totalPages: number): any => {
        return {
          payload: {
            totalRecords,
            totalPages,
            pages: Array.from({ length: totalPages }, (v, k) => k + 1),
          },
        };
      },
    },
  },
});

export default tableSlice.reducer;

export const { setPagination, setPageLimit, updateSorting, updateCurrentPage, updateTotalPages } = tableSlice.actions;
