import { useEffect, Suspense, useState } from 'react';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';

import useRouterDom from '../../hooks/useRouterDom';
import { useLazyGetUserContextQuery } from '../../store/services/userContextApi';
import { useFindOneFileQuery } from '../../features/FileUpload/store/fileApi';

interface ViewFileWrapperProps {
  children: JSX.Element;
}

function ViewFileWrapper({ children }: ViewFileWrapperProps) {
  const { searchParams } = useRouterDom();
  const [getUserContext] = useLazyGetUserContextQuery();
  const { data: fileData, isSuccess: isFileDataSuccess } = useFindOneFileQuery({ filename: searchParams.get('name') });
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isFileDataSuccess) {
        // check if its free or paid
        if (fileData.access === 'Paid') {
          // check if its logged in. if not navigate to login
          const isLoggedIn = await getUserContext('');
          // not logged in navigate to login
          if (!isLoggedIn.error) {
            setDisplay(true);
          }
        }

        if (fileData.access === 'Free') {
          setDisplay(true);
        }
      }
    };

    fetchData().catch(console.error);
  }, [isFileDataSuccess]);

  return <Suspense fallback={<LoadingScreen />}>{display ? children : <></>}</Suspense>;
}

export default ViewFileWrapper;
