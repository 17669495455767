import { useEffect, Suspense, useState } from 'react';
import LoadingScreen from '../../../components/Loading/LoadingScreen/LoadingScreen';

import useRouterDom from '../../../hooks/useRouterDom';
import { useLazyGetUserContextQuery } from '../../../store/services/userContextApi';
import { useFindOnePageQuery } from '../../../features/PageBuilder/store/pageBuilderApi';
import config from '../../../config';

interface PageBuilderViewPageWrapperProps {
  children: JSX.Element;
}

function PageBuilderViewPageWrapper({ children }: PageBuilderViewPageWrapperProps) {
  const { urlParams } = useRouterDom();
  const [getUserContext] = useLazyGetUserContextQuery();
  const { data: pageData, isSuccess: isPageDataSuccess } = useFindOnePageQuery({ slug: urlParams.slug, status: 'Published' });
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isPageDataSuccess) {
        // check if pageData is not null
        if (pageData === null) {
          // redirect
          window.location.href = `${config.subsitesUrl.main}/login`;
        }

        // check if its free or paid
        if (pageData.access === 'Paid') {
          // check if its logged in. if not navigate to login
          const isLoggedIn = await getUserContext('');

          // not logged in navigate to login
          if (isLoggedIn.error) {
            window.location.href = `${config.subsitesUrl.main}/login`;
          } else {
            setDisplay(true);
          }
        }

        if (pageData.access === 'Free') {
          setDisplay(true);
        }
      }
    };

    fetchData().catch(console.error);
  }, [isPageDataSuccess]);

  return <Suspense fallback={<LoadingScreen />}>{display ? children : <></>}</Suspense>;
}

export default PageBuilderViewPageWrapper;
