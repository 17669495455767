import { ISecurityOption, IVerificationOption } from './profile_interfaces';

export enum SecuritySteps {
  SECURITY_OPTIONS,
  VERIFICATION,
  OTP,
  INPUT,
  SUCCESS,
}

export const SchoolSecurityDetails = {
  PASSWORD: 'Password',
  SCHOOL_PRIMARY_EMAIL: 'School Primary Email',
  SCHOOL_PHONE_NUMBER: 'School Phone Number',
  CONTACT_PERSON_EMAIL: 'Contact Person Email',
  CONTACT_PERSON_PHONE: 'Contact Person Phone Number',
};

export const PersonalSecurityDetails = {
  PASSWORD: 'Password',
  PRIMARY_EMAIL: 'Primary Email',
  SECONDARY_EMAIL: 'Secondary Email',
  PHONE_NUMBER: 'Phone Number',
};

export const SchoolVerificationCategory = {
  SCHOOL_PRIMARY_EMAIL: "School's Primary Email",
  SCHOOL_PHONE_NUMBER: "School's Phone Number",
  CONTACT_PERSON_EMAIL: "Contract Person's Email",
  CONTACT_PERSON_PHONE: "Contact Person's Phone Number",
};

export enum SchoolVerficationMethod {
  PASSWORD,
  SCHOOL_PRIMARY_EMAIL,
  SCHOOL_PHONE_NUMBER,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_PHONE,
}

export const SchoolSecurityOptions: ISecurityOption[] = [
  {
    securityDetail: SchoolSecurityDetails.PASSWORD,
    name: 'Password',
    type: 'password',
    icon: 'icon-lock-regular',
  },
  {
    securityDetail: SchoolSecurityDetails.SCHOOL_PRIMARY_EMAIL,
    name: 'School Primary Email',
    data: '...',
    type: 'primary_email',
    icon: 'icon-envelope',
  },
  {
    securityDetail: SchoolSecurityDetails.SCHOOL_PHONE_NUMBER,
    name: 'School Phone Number',
    data: '...',
    type: 'phone',
    icon: 'icon-phone-solid',
  },
  {
    securityDetail: SchoolSecurityDetails.CONTACT_PERSON_EMAIL,
    name: 'Contact Person Email',
    data: '...',
    type: 'contact_person_email',
    icon: 'icon-envelope',
  },
  {
    securityDetail: SchoolSecurityDetails.CONTACT_PERSON_PHONE,
    name: 'Contact Person Phone Number',
    data: '...',
    type: 'contact_person_mobile',
    icon: 'icon-phone-solid',
  },
];

export let PersonalSecurityOptions: ISecurityOption[] = [
  {
    securityDetail: PersonalSecurityDetails.PASSWORD,
    name: 'Password',
    type: 'password',
    icon: 'icon-lock-regular',
  },
  {
    securityDetail: PersonalSecurityDetails.PRIMARY_EMAIL,
    name: 'Primary Email',
    data: '...',
    type: 'primary_email',
    icon: 'icon-envelope',
  },
  {
    securityDetail: PersonalSecurityDetails.SECONDARY_EMAIL,
    name: 'Secondary Email',
    data: '...',
    type: 'secondary_email',
    icon: 'icon-envelope',
  },
  {
    securityDetail: PersonalSecurityDetails.PHONE_NUMBER,
    name: 'Phone Number',
    data: '...',
    type: 'phone',
    icon: 'icon-phone-solid',
  },
];

export const SchoolVerification: IVerificationOption[] = [
  {
    method: 'Email Verification',
    category: SchoolVerificationCategory.SCHOOL_PRIMARY_EMAIL,
    data: '...',
    type: 'primary_email',
  },
  {
    method: 'Email Verification',
    category: SchoolVerificationCategory.CONTACT_PERSON_EMAIL,
    data: '...',
    type: 'contact_person_email',
  },
  {
    method: 'SMS Verification',
    category: SchoolVerificationCategory.SCHOOL_PHONE_NUMBER,
    data: '...',
    type: 'phone',
  },
  {
    method: 'SMS Verification',
    category: SchoolVerificationCategory.CONTACT_PERSON_PHONE,
    data: '...',
    type: 'contact_person_mobile',
  },
];

export const PersonalVerification: IVerificationOption[] = [
  {
    method: 'Email Verification',
    category: PersonalSecurityDetails.PRIMARY_EMAIL,
    data: '...',
    type: 'primary_email',
  },
  {
    method: 'Email Verification',
    category: PersonalSecurityDetails.SECONDARY_EMAIL,
    data: '...',
    type: 'secondary_email',
  },
  {
    method: 'SMS Verification',
    category: PersonalSecurityDetails.PHONE_NUMBER,
    data: '...',
    type: 'phone',
  },
];
