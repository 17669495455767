import { gql } from 'graphql-request';
import { baseApi } from './baseApi';

export const searchResourceApi = baseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    globalSearch: builder.query({
      query: (filter: any) => ({
        body: gql`
          query Query($filter: GlobalSearchFilter!) {
            globalSearch(filter: $filter) {
              id
              name
              border_color
              subsite_code
              image {
                url
                resource_type
              }
            }
          }
        `,
        variables: {
          filter,
        }
      }),
      transformResponse: (res: any) => res.globalSearch,
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGlobalSearchQuery } = searchResourceApi;
