import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../../store/store';

interface PageBuilderState {
  isModalOpen: boolean;
}

const initialState: PageBuilderState = {
  isModalOpen: false,
};

export const pageBuilderSlice = createSlice({
  name: 'pageBuilder',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    resetModal: (state) => {
      state.isModalOpen = false;
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
  },
});

export const { reset, resetModal, openModal, closeModal, toggleModal } = pageBuilderSlice.actions;

export const selectPageBuilder = (state: RootState) => state.pageBuilder;

export default pageBuilderSlice.reducer;
