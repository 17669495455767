import { basePersistentApi } from '../baseApi';
import { gql } from 'graphql-request';
import config from '../../config';

export const userContextApi = basePersistentApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // queries
    getUserContext: builder.query({
      query: () => ({
        body: gql`
          query GetUserContext {
            getUserContext {
              id
              username
              email
              is_institution
              is_verified
              is_active
              user_profile {
                id
                first_name
                last_name
                date_of_birth
                is_real_birthdate
                primary_email
                secondary_email
                mobile_number
                affiliation
                state
                postcode
              }
              institution_profile {
                id
                institution_name
                primary_email
                address_line1
                address_line2
                city
                state
                postcode
                phone_number
                contact_person {
                  name
                  email
                  mobile_number
                }
              }
              roles {
                id
                role {
                  name
                  short_code
                  id
                  allowed_subsites {
                    short_code
                    name
                    url
                  }
                }
                assigned_by {
                  id
                  username
                  email
                  is_institution
                  user_profile {
                    id
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
              licenses {
                id
                is_assigned
                is_renewed
                date_assigned
                assigned_by
                assignee_email
                licence_assignee {
                  id
                  email
                  is_institution
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
            }
          }
        `,
      }),
      transformResponse: (res: any) => res.getUserContext,
    }),
    getUserLatestSubscription: builder.query({
      query: () => ({
        body: gql`
          query FindUserLatestSubscription($subsiteCode: String) {
            findUserLatestSubscription(subsite_code: $subsiteCode) {
              subscription {
                activation_date
                created_at
                expiration_date
                id
                product_reference_id
                invoices {
                  invoice_no
                  duration_in_month
                  payment_status
                }
                is_auto_renew
                is_self_assign
                purchase_date
                is_active
                subsite {
                  name
                }
                subsite_short_code
                unassigned_license_count
                unpaid_license_count
              }
              active_license_count
              pending_payments_count
              is_subscribed
              is_legacy
            }
          }
        `,
        variables: {
          subsiteCode: config.subsiteCode,
        },
      }),
      transformResponse: (res: any) => res.findUserLatestSubscription,
    }),
    getAllUserSubscription: builder.query({
      query: () => ({
        body: gql`
          query FindAllUserSubscription($subsiteCode: String) {
            findAllUserSubscription(subsite_code: $subsiteCode) {
              activation_date
              assigned_licence_count
              created_at
              expiration_date
              id
              is_active
              is_auto_renew
              licence_count
              renewal_activation_date
              renewal_expiration_date
              renewal_licence_count
              subsite_short_code
              is_legacy
              updated_at
              product_snapshot {
                id
                name
                pricing {
                  price
                  duration_in_months
                }
                is_group
              }
              user_invoice {
                created_at
                id
                invoice_no
                updated_at
                user_payment_id
              }
              licenses {
                assignee_email
              }
            }
          }
        `,
        variables: {
          subsiteCode: config.subsiteCode,
        },
      }),
      transformResponse: (res: any) => res.findAllUserSubscription,
    }),
    getUserById: builder.query({
      query: (id: String) => ({
        body: gql`
          query FindUserById($findUserByIdId: ID!) {
            findUserById(id: $findUserByIdId) {
              id
            }
          }
        `,
        variables: {
          findUserByIdId: id,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserContextQuery,
  useLazyGetUserContextQuery,
  useGetUserLatestSubscriptionQuery,
  useLazyGetUserLatestSubscriptionQuery,
  useLazyGetAllUserSubscriptionQuery,
  useLazyGetUserByIdQuery,
} = userContextApi;

// @ts-ignore
export const useGetUserContext = userContextApi.endpoints.getUserContext.useQueryState;
// @ts-ignore
export const useGetAllUserSubscription = userContextApi.endpoints.getAllUserSubscription.useQueryState;
