import { gql } from 'graphql-request';
import { baseApi } from '../../../store/baseApi';

export const resourceFilterApi = baseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    //queries
    getGradeLevels: builder.query({
      query: () => ({
        body: gql`
          query FindGradeLevels {
            findGradeLevels {
              id
              code
              name
              scope_and_sequence_id
              scope_and_sequence {
                display_name
                downloadable
                file_type
                filename
                is_private
                resource_type
                subsite_short_code
                upload_status
                size_in_bytes
                url
                id
                updated_at
              }
            }
          }
        `,
      }),
      transformResponse: (res: any) => res.findGradeLevels,
    }),
    getConceptZones: builder.query({
      query: () => ({
        body: gql`
          query FindConceptZones {
            findConceptZones {
              id
              name
              created_at
              main_color
              secondary_color
              view_order
              zone_sequence {
                display_name
                downloadable
                file_type
                filename
                id
                is_private
                reference_id
                resource_type
                size_in_bytes
                subsite_short_code
                updated_at
                upload_status
                url
              }
            }
          }
        `,
      }),
      transformResponse: (res: any) => res.findConceptZones,
    }),
  }),
});

export const { useGetGradeLevelsQuery, useGetConceptZonesQuery } = resourceFilterApi;
