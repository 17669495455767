/* eslint-disable @typescript-eslint/no-unused-vars */
import { Suspense, useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import cookie from '../../helpers/cookieHelper';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';
import { useGetUserContextQuery } from '../../store/services/userContextApi';
import config from '../../config';
import { redirectLogin } from '../../helpers/utilityFuncions';

function ProtectedRouteWrapper() {
  const { data: userData, isLoading, isFetching } = useGetUserContextQuery('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  //ensures that the screen scrolls to top when location changes
  useEffect(() => window.scrollTo(0, 0), [location]);

  useEffect(() => {
    if (!cookie.isLoggedIn()) {
      redirectLogin();
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const user = userData as any;

    // @TODO: This is temporary only. revert these changes once everything is stable
    if (location.pathname.includes('subscription')) {
      if (user && user.roles.length > 0 && user.roles.find((r: any) => r.role.short_code == 'FREESUB'))
        window.location.href = '/';
    }

    //if the user is logged in but not yet verified and trying to access protected
    //routes other than emailverification, redirect to emailverification page
    if (user && !user?.is_verified && !location.pathname.includes('emailverification')) {
      window.location.href = `${config.subsitesUrl.main}/createaccount/${
        user?.is_institution ? 'school' : 'personal'
      }/emailverification/${user?.email}/true`;
    }
  }, [userData, isLoading, isFetching]);

  if (!isAuthenticated || !userData) return <LoadingScreen />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
}

export default ProtectedRouteWrapper;
