import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHooks';
import { toggleResourceFilterVisible, setSelectedResourceFilter } from '../store/layoutSlice';
import { useGetConceptZonesQuery, useGetGradeLevelsQuery } from '../store/resourceFilterApi';

function useResourceFilter() {
  const dispatcher = useAppDispatch();
  const { isSidemenuIconView, showResourceFilter, selectedResourceFilter } = useAppSelector((state) => state.layout);
  const {
    data: gradeLevels,
    isLoading: isGradeLevelsLoading,
    isFetching: isGradeLevelsFetching,
  } = useGetGradeLevelsQuery('', {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: conceptZones,
    isLoading: isConceptZonesLoading,
    isFetching: isConceptZonesFetching,
  } = useGetConceptZonesQuery('', {
    refetchOnMountOrArgChange: true,
  });
  const [filesByGrade, setFilesByGrade] = useState<any[]>([]);
  const [filesByConcept, setFilesByConcept] = useState<any[]>([]);

  const toggleResourceFilter = (show: boolean) => {
    dispatcher(toggleResourceFilterVisible(show));
  };

  const setSelectedFilter = (filterId: string) => {
    dispatcher(setSelectedResourceFilter(filterId));
  };

  useEffect(() => {
    if (!isGradeLevelsFetching && !isGradeLevelsLoading && gradeLevels) {
      setFilesByGrade(
        (gradeLevels as any[]).map((grade: any) => {
          return {
            ...grade,
            scope_and_sequence: grade.scope_and_sequence ? [grade.scope_and_sequence] : [],
          };
        })
      );
    }
  }, [gradeLevels, isGradeLevelsFetching, isGradeLevelsLoading]);

  useEffect(() => {
    if (!isConceptZonesFetching && !isConceptZonesLoading && conceptZones) {
      const sortedZones = (conceptZones as any[])
        ?.map((cz: any) => {
          return {
            ...cz,
            created_at: Date.parse(cz.created_at),
          };
        })
        .sort((a: any, b: any) => a.created_at - b.created_at);

      setFilesByConcept(
        sortedZones.map((concept: any) => {
          return {
            ...concept,
            zone_sequence: concept.zone_sequence ? [concept.zone_sequence] : [],
          };
        })
      );
    }
  }, [conceptZones, isConceptZonesFetching, isConceptZonesLoading]);

  return {
    filesByGrade,
    filesByConcept,
    isSidemenuIconView,
    showResourceFilter,
    selectedResourceFilter,
    toggleResourceFilter,
    setSelectedFilter,
  };
}

export default useResourceFilter;
