import { useNode } from '@craftjs/core';

import Resizer from '../Resizer';
import ImageSettings from './ImageSettings';

type ImageProps = {
  src: string;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  width: string;
  height: string;
  padding: string[];
  margin: string[];
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
};

const defaultProps = {
  src: '',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  width: '100%',
  height: '100%',
};

function Image(props: Partial<ImageProps>) {
  const {
    connectors: { connect },
  } = useNode();

  props = {
    ...defaultProps,
    ...props,
  };

  const { src, flexDirection, alignItems, justifyContent, padding, margin } = props;

  return (
    <Resizer
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="Image"
      propKey={{ width: 'width', height: 'height' }}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
      }}
    >
      {src && <img src={src} className="tw-h-full tw-w-full" />}
    </Resizer>
  );
}

Image.craft = {
  displayName: 'Image',
  props: defaultProps,
  related: {
    toolbar: ImageSettings,
  },
};

export default Image;
