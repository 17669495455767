import { adminBaseApi } from '../adminBaseApi';
import { gql } from 'graphql-request';

export const adminApi = adminBaseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // mutations
    loginBackAsAdmin: builder.mutation({
      query: () => ({
        body: gql`
          mutation Mutation {
            loginBackAsAdmin {
              access_token
              refresh_token
            }
          }
        `,
      }),
      transformResponse: (res: any): any => res.impersonateLogin,
    }),
  }),
  overrideExisting: false,
});

export const { useLoginBackAsAdminMutation } = adminApi;
