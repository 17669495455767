import { SecuritySteps } from '@pages/SubscriptionManagement/MyProfile/utils/profile_constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfileSecurityOtpRequest, ISetSecurityDetail, SecurityDetailType } from '../utils/profile_interfaces';

interface ProfileProps {
  currentStep: SecuritySteps;
  selectedSecurityDetail: string | null;
  selectedSecurityDetailType: SecurityDetailType | null;
  selectedVerificationOption: string | null;
  otpRequest: IProfileSecurityOtpRequest | null;
  securityErrorMessage: string | null;
}

const initialState: ProfileProps = {
  currentStep: SecuritySteps.SECURITY_OPTIONS,
  selectedSecurityDetail: null,
  selectedSecurityDetailType: null,
  selectedVerificationOption: null,
  otpRequest: null,
  securityErrorMessage: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<SecuritySteps>) => {
      state.currentStep = action.payload;
    },
    setSecurityDetail: (state, action: PayloadAction<ISetSecurityDetail | null>) => {
      state.selectedSecurityDetail = action.payload?.securityDetail ?? null;
      state.selectedSecurityDetailType = action.payload?.type ?? null;
    },
    setSelectedVerification: (state, action: PayloadAction<string | null>) => {
      state.selectedVerificationOption = action.payload;
    },
    recordOtpRequest: (state, action: PayloadAction<IProfileSecurityOtpRequest | null>) => {
      state.otpRequest = action.payload;
    },
    setSecurityErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.securityErrorMessage = action.payload;
    },
  },
});

export default profileSlice.reducer;

export const { setCurrentStep, setSecurityDetail, setSelectedVerification, recordOtpRequest, setSecurityErrorMessage } =
  profileSlice.actions;
