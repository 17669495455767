import { useState, useEffect } from 'react';
import './UserCard.scss';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AnimatedPulse from '../../../components/Loading/AnimatedPulse/AnimatedPulse';
import { useLogoutMutation } from '../../../store/services/authApi';
import { useLoginBackAsAdminMutation } from '../../../store/services/adminApi';
import { useNavigate } from 'react-router-dom';
import { useLazyGetSubscriptionAndLicencesQuery } from '../store/subscriptionAndLicenceApi';
import { getFormattedDate } from '../../../helpers/dateHelper';
import cookieHelper from '../../../helpers/cookieHelper';

interface UserCardProps {
  name: string;
  isInstitution?: Boolean; //we need to identify whether to display one or two initials
  userId?: string;
  // role?: string;
}

function UserCard({ name, isInstitution = false, userId }: UserCardProps) {
  const navigate = useNavigate();
  const [logout, formStatus] = useLogoutMutation();
  const [loginBackAsAdmin, adminFormStatus] = useLoginBackAsAdminMutation();
  const [initials, setInitials] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allLicenceAndSubscription, setAllLicenceAndSubscription] = useState<any[]>([]);
  const toggle = () => setDropdownOpen((prevState: boolean) => !prevState);
  const [getSubscriptionAndLicences, { data }] = useLazyGetSubscriptionAndLicencesQuery();

  useEffect(() => {
    if (name) {
      const nameArray = name.split(' ').filter((s) => s && s != '');
      getSubscriptionAndLicences(userId);
      if (!isInstitution) setInitials(`${nameArray[0][0]}${nameArray.length > 1 ? nameArray[1][0] : ''}`.toLocaleUpperCase());
      else setInitials(`${nameArray[0][0]}`.toLocaleUpperCase());
    }

    // if (role) setLicense(licenseDetails.get(role));
  }, [name]);

  useEffect(() => {
    if (data) setAllLicenceAndSubscription([...(data as any)?.licences] ?? []);
  }, [data]);

  useEffect(() => {
    if (formStatus.isError) {
      alert('Something went wrong. Please try again.');
    } else if (formStatus.isSuccess) {
      // Navigate to home page after logging out
      window.location.replace('/');
    }
  }, [formStatus]);

  useEffect(() => {
    if (adminFormStatus.isError) {
      alert('Something went wrong. Please try again.');
    } else if (adminFormStatus.isSuccess) {
      window.location.reload();
    }
  }, [adminFormStatus]);

  const expirationChecker = allLicenceAndSubscription?.filter(
    (sub: any) => new Date(sub?.expiration_date) > new Date() || new Date(sub?.user_subscription?.expiration_date) > new Date()
  );

  const getName = (user: any) => {
    return user?.is_institution
      ? user?.institution_profile?.institution_name
      : `${user?.user_profile?.first_name} ${user?.user_profile?.last_name}`;
  };

  return (
    <div className="user-card-wrapper">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
        <DropdownToggle caret>
          {name ? (
            <div className="user-info-avatar-wrap">
              <div className="user-avatar">{initials}</div>
            </div>
          ) : (
            <AnimatedPulse color="green" />
          )}
        </DropdownToggle>
        <DropdownMenu>
          <>
            <DropdownItem>
              <div className="user-info-avatar-wrap flex">
                <div className="user-avatar">{initials}</div>
                <div className="user-name-wrap text-capitalize">{name}</div>
              </div>
            </DropdownItem>
            {expirationChecker?.map((item: any, key: number) => (
              <DropdownItem key={key} onClick={() => navigate('/dashboard/tr')}>
                <div className="assignedby-list">
                  <span className="assignedby-header">Assigned By:</span>{' '}
                  <span className="assignedby-name" title={getName(item?.licence_assignee)}>{getName(item?.licence_assignee)}</span>
                  <br />
                  <span className="assignedby-date">{`${getFormattedDate(item?.user_subscription?.activation_date)} -
                        ${getFormattedDate(item?.user_subscription?.expiration_date)}`}</span>
                </div>
              </DropdownItem>
            ))}
          </>

          {cookieHelper.isAdminImpersonating() && (
            <DropdownItem onClick={() => loginBackAsAdmin('')}>
              <i className="icon icon-back-arrow" /> Login back as Admin
            </DropdownItem>
          )}

          <DropdownItem onClick={() => logout('')}>
            <i className="icon icon-logout" /> Log out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default UserCard;
