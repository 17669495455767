import { baseApi } from '../baseApi';
import { gql } from 'graphql-request';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // queries
    checkIfSubscribe: builder.query({
      query: (email: String) => ({
        body: gql`
          query FindActiveLicences($email: String!) {
            findActiveLicences(email: $email) {
              id
              is_assigned
              is_renewed
              date_assigned
              assigned_by
              assignee_email
            }
          }
        `,
        variables: {
          email,
        },
      }),
      transformResponse: (res: any): any => res.findActiveLicences,
    }),

    // mutations
    login: builder.mutation({
      query: ({ userEmailorUsername, password }: any) => ({
        body: gql`
          mutation Login($userEmailorUsername: String, $password: String, $subsite: String) {
            login(userEmailorUsername: $userEmailorUsername, password: $password, subsite: $subsite) {
              id
              access_token
              refresh_token
              expires_at
              browser
              platform
            }
          }
        `,
        variables: {
          userEmailorUsername,
          password,
          subsite: 'MAIN',
        },
      }),
      transformResponse: (res: any): any => res.login,
    }),
    logout: builder.mutation({
      query: () => ({
        body: gql`
          mutation Logout {
            logout {
              success
            }
          }
        `,
      }),
      transformResponse: (res: any): any => res.logout,
    }),
  }),
  overrideExisting: false,
});
export const { useLazyCheckIfSubscribeQuery, useLoginMutation, useLogoutMutation } = authApi;
