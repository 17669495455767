import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './ResourceFilter.scss';
import useResourceFilter from '../hooks/useResourceFilter';
import { boxColor } from '../Utils/constants';
import useRouterDom from '@hooks/useRouterDom';
import AnimatedPulse from '@components/Loading/AnimatedPulse/AnimatedPulse';
import cookie from '@helpers/cookieHelper';

const StyledLi = styled.li`
  &.concept-menu {
    color: ${(props: any) => props?.$mainColor};
    background-color: ${(props: any) => props?.$secondaryColor};
    border-color: ${(props: any) => props?.$mainColor} !important;
    &.box-selected {
      color: #fff;
      background-color: ${(props: any) => props?.$mainColor};
    }
  }
`;

function ResourceFilter() {
  const { isSidemenuIconView, showResourceFilter, selectedResourceFilter, setSelectedFilter, filesByGrade, filesByConcept } =
    useResourceFilter();

  const { searchParams } = useRouterDom();
  const navigate = useNavigate();

  const navigateToGrade = (selectedResourceFilter: any) => {
    navigate(`/dashboard/tr?selected=${selectedResourceFilter}`, { state: { accordionState: [] } });
    setSelectedFilter(selectedResourceFilter);
  };

  const showResourceClass = useMemo(() => {
    if (showResourceFilter && !cookie.isLoggedIn()) {
      return 'show-resource-filter';
    }

    return 'show-resource-filter-authenticated';
  }, [showResourceFilter, cookie]);

  const sortedConcepts = filesByConcept?.sort((a: any, b: any) => a.view_order - b.view_order);
  const getFiltersDisplay = (isFromGrade: boolean) => {
    let ctr = 0;
    if (isFromGrade) {
      if (filesByGrade?.length) {
        return filesByGrade.map((item: any, key: number) => {
          const boxitem = (
            <li
              key={key}
              className={`clickable box-${boxColor.get(ctr)}${
                selectedResourceFilter === item.code.toString() ? ' box-selected' : ''
              }`}
              onClick={() => navigateToGrade(item.code.toString())}
            >
              <div className="inner-border">{item?.name}</div>
            </li>
          );
          ctr = ctr >= 6 ? 0 : ctr + 1;
          return boxitem;
        });
      } else {
        return <div>No Scope and Sequences found</div>;
      }
    } else {
      if (filesByConcept?.length) {
        return sortedConcepts.map((item: any, key: number) => {
          const boxItem = (
            <StyledLi
              key={key}
              className={`clickable concept-menu ${selectedResourceFilter === item?.id ? 'box-selected' : ''}`}
              onClick={() => navigateToGrade(item?.id)}
              //@ts-ignore
              $mainColor={item?.main_color}
              $secondaryColor={item?.secondary_color}
            >
              <div className="inner-border">{item?.name?.toUpperCase()}</div>
            </StyledLi>
          );
          ctr = ctr >= 6 ? 0 : ctr + 1;
          return boxItem;
        });
      } else {
        return <div>No Concept Zone Sequences found</div>;
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('selected')) {
      setSelectedFilter(searchParams.get('selected') ?? '');
    }
  }, []);
  //
  useEffect(() => {
    //
    window.scroll(0, 0);
  }, [selectedResourceFilter]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [selectedResourceFilter]);

  return (
    <section className={`resource-filter flex flex-col ${showResourceClass}${isSidemenuIconView ? ' menu-iconview-mode' : ''}`}>
      <div className="w-100 flex flex-row">
        <div className="w-100 input-icon-group">
          {/* <i className="icon icon-search" />
          <Input className="search-filter" placeholder="Search grade, concept" /> */}
        </div>
      </div>

      <div className="filter-list no-divider">
        <h6>
          <div>TEACHING RESOURCES</div>
          <div>BY GRADE</div>
        </h6>
        {filesByGrade?.length ? (
          <ul className="flex flex-col">
            <>{getFiltersDisplay(true)}</>
          </ul>
        ) : (
          <AnimatedPulse color="green" />
        )}
      </div>

      <div className="filter-list">
        <h6>
          <div>TEACHING RESOURCES BY CONCEPT ZONE</div>
        </h6>
        {filesByConcept?.length ? (
          <ul className="flex flex-col">
            <>{getFiltersDisplay(false)}</>
          </ul>
        ) : (
          <AnimatedPulse color="green" />
        )}
      </div>
    </section>
  );
}

export default ResourceFilter;
