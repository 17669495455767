import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHooks';
import { toggleSideMenu, toggleSidemenuIconView, toggleResourceFilterVisible } from '../store/layoutSlice';
import { isCurrentMenuASidemenu, isCurrentMenuUseResourceFilter } from '../Utils/utilityFunctions';
import { useLazyCheckIfSubscribeQuery } from '../../../store/services/authApi';
import { useGetUserContextQuery } from '../../../store/services/userContextApi';

function useLayout() {
  const location = useLocation();
  const dispatcher = useAppDispatch();
  const [currentPath, setCurrentPath] = useState('');
  const { data: userData } = useGetUserContextQuery('');
  const [checkIfSubscribe, { data: isSubscribed }] = useLazyCheckIfSubscribeQuery();
  const { isSideNavigationVisible, sideMenuId, isSidemenuIconView, showResourceFilter } = useAppSelector((state) => state.layout);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const user = userData as any;
    if (user) {
      if (user?.email) {
        checkIfSubscribe(user?.email);
      }
    }
  }, [userData]);

  useEffect(() => {
    const check = isSubscribed as [];
    if (check) {
      if (check.length > 0) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [isSubscribed]);

  useEffect(() => {
    //this use Effect monitors when to show side menu
    //only show side menu when the url path is a sidemenu path
    const { isSideMenu, sideMenuId: menuId } = isCurrentMenuASidemenu(location.pathname);
    dispatcher(toggleSideMenu(isSideMenu, menuId));

    //always check if we need to hide resource filter
    dispatcher(toggleResourceFilterVisible(isCurrentMenuUseResourceFilter(location.pathname)));

    //update current path
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const toggleIconView = () => {
    dispatcher(toggleSidemenuIconView(!isSidemenuIconView));
  };
  return {
    currentPath,
    sideMenuId,
    isSideNavigationVisible,
    isSidemenuIconView,
    showResourceFilter,
    checked,
    toggleIconView,
  };
}

export default useLayout;
