import Resizer from '../Resizer';
import ContainerSettings from './ContainerSettings';

type ContainerProps = {
  background: Record<'r' | 'g' | 'b' | 'a', number>;
  color: Record<'r' | 'g' | 'b' | 'a', number>;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  fillSpace: string;
  minWidth: string;
  width: string;
  minHeight: string;
  height: string;
  padding: string[];
  margin: string[];
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
  shadow: number;
  children: React.ReactNode;
  radius: number;
};

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 0, g: 0, b: 0, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
  minWidth: '10px',
  width: '100%',
  minHeight: '10px',
  height: 'auto',
};

function Container(props: Partial<ContainerProps>) {
  props = {
    ...defaultProps,
    ...props,
  };

  const { flexDirection, alignItems, justifyContent, fillSpace, background, color, padding, margin, shadow, radius, children } =
    props;

  return (
    <Resizer
      propKey={{ width: 'width', height: 'height' }}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        background: `rgba(${Object.values(background as ContainerProps['background'])})`,
        color: `rgba(${Object.values(color as ContainerProps['color'])})`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        boxShadow: shadow === 0 ? 'none' : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: `${radius}px`,
        flex: fillSpace === 'yes' ? 1 : 'unset',
      }}
    >
      {children}
    </Resizer>
  );
}

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};

export type { ContainerProps };
export default Container;
