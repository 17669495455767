import { baseApi } from '../../../store/baseApi';
import { gql } from 'graphql-request';

export const pdfFileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    findPDFFileList: builder.query({
      query: ({ keyword, paginate }) => ({
        body: gql`
          query FindPDFFileList($keyword: String, $paginate: PaginationAggregateOptionsInput) {
            findPDFFileList(keyword: $keyword, paginate: $paginate) {
              docs {
                _id
                filename
                pdf_file_url
                access
                updated_at
                uploaded_by {
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
              pagination {
                has_next_page
                has_prev_page
                limit
                next_page
                page
                paging_counter
                prev_page
                total_docs
                total_pages
              }
            }
          }
        `,
        variables: {
          keyword,
          paginate,
        },
      }),
      transformResponse: (res: any): any => res.findPDFFileList,
      keepUnusedDataFor: 0,
    }),
    findPDFFileById: builder.query({
      query: (id) => ({
        body: gql`
          query FindPDFFileById($id: ID!) {
            findPDFFileById(id: $id) {
              id
              filename
              pdf_file_url
              access
              created_at
              uploaded_by_useraccount_id
              uploaded_by {
                user_profile {
                  first_name
                  last_name
                }
                institution_profile {
                  institution_name
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.findPDFFileById,
      keepUnusedDataFor: 1,
    }),
    findOnePDFFile: builder.query({
      query: (filter) => ({
        body: gql`
          query FindOnePDFFile($filter: JSON) {
            findOnePDFFile(filter: $filter) {
              id
              filename
              pdf_file_url
              access
              created_at
              uploaded_by_useraccount_id
              uploaded_by {
                user_profile {
                  first_name
                  last_name
                }
                institution_profile {
                  institution_name
                }
              }
            }
          }
        `,
        variables: {
          filter,
        },
      }),
      transformResponse: (res: any): any => res.findOnePDFFile,
      keepUnusedDataFor: 1,
    }),

    // mutations
    createPDFFile: builder.mutation({
      query: (data) => ({
        body: gql`
          mutation CreatePDFFile($data: PDFFileContent) {
            createPDFFile(data: $data) {
              pdfFile {
                id
                filename
                pdf_file_url
                access
                created_at
                uploaded_by_useraccount_id
              }
              uploadUrl
            }
          }
        `,
        // {
        //   "data": {
        //     "filename": null,
        //     "uploaded_by_useraccount_id": null
        //   }
        // }
        variables: {
          data,
        },
      }),
      transformResponse: (res: any): any => res.createPDFFile,
    }),
    updateExistingPDFFileById: builder.mutation({
      query: ({ id, access, subsite }) => ({
        body: gql`
          mutation UpdateExistingPDFFileById($id: ID, $access: String, $subsite: String) {
            updateExistingPDFFileById(id: $id, access: $access, subsite: $subsite) {
              pdfFile {
                id
              }
              uploadUrl
            }
          }
        `,
        variables: {
          id,
          access,
          subsite,
        },
      }),
      transformResponse: (res: any): any => res.updateExistingPDFFileById,
    }),
    updateUploadedPDFFileById: builder.mutation({
      query: (id) => ({
        body: gql`
          mutation UpdateUploadedPDFFileById($id: ID) {
            updateUploadedPDFFileById(id: $id) {
              id
              filename
              pdf_file_url
              access
              created_at
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.updateUploadedPDFFileById,
    }),
    deletePDFFileById: builder.mutation({
      query: ({ id, subsite }) => ({
        body: gql`
          mutation DeletePDFFileById($id: ID) {
            deletePDFFileById(id: $id)
          }
        `,
        variables: {
          id,
          subsite,
        },
      }),
      transformResponse: (res: any): any => res.deletePDFFileById,
    }),
  }),
});

export const {
  useFindPDFFileListQuery,
  useLazyFindPDFFileListQuery,
  useFindPDFFileByIdQuery,
  useFindOnePDFFileQuery,
  useLazyFindOnePDFFileQuery,
  useCreatePDFFileMutation,
  useUpdateExistingPDFFileByIdMutation,
  useUpdateUploadedPDFFileByIdMutation,
  useDeletePDFFileByIdMutation,
} = pdfFileApi;
