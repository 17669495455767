import { baseApi } from '../../../store/baseApi';
import { gql } from 'graphql-request';

export const pageBuilderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    findPageList: builder.query({
      query: ({ keyword, paginate }) => ({
        body: gql`
          query FindPageList($keyword: String, $paginate: PaginationAggregateOptionsInput) {
            findPageList(keyword: $keyword, paginate: $paginate) {
              docs {
                _id
                title
                subsite
                slug
                page_data
                access
                status
                created_by_useraccount_id
                created_by {
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
                published_by_useraccount_id
                published_by {
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
                created_at
                updated_at
              }
              pagination {
                has_next_page
                has_prev_page
                limit
                next_page
                page
                paging_counter
                prev_page
                total_docs
                total_pages
              }
            }
          }
        `,
        variables: {
          keyword,
          paginate,
        },
      }),
      transformResponse: (res: any): any => res.findPageList,
    }),
    findPageById: builder.query({
      query: (id) => ({
        body: gql`
          query FindPageById($id: ID!) {
            findPageById(id: $id) {
              id
              title
              slug
              access
              status
              subsite
              page_data
              published_by_useraccount_id
              created_by_useraccount_id
              created_at
              updated_at
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.findPageById,
      keepUnusedDataFor: 1,
    }),
    findOnePage: builder.query({
      query: (filter) => ({
        body: gql`
          query FindOnePage($filter: JSON) {
            findOnePage(filter: $filter) {
              id
              title
              slug
              access
              status
              subsite
              page_data
              published_by_useraccount_id
              created_by_useraccount_id
              created_at
              updated_at
            }
          }
        `,
        variables: {
          filter,
        },
      }),
      transformResponse: (res: any): any => res.findOnePage,
      keepUnusedDataFor: 1,
    }),

    // mutations
    createPage: builder.mutation({
      query: (data) => ({
        body: gql`
          mutation CreatePage($data: PageContent!) {
            createPage(data: $data) {
              id
            }
          }
        `,
        // {
        //   "data": {
        //     "created_by_useraccount_id": null,
        //     "is_publish": null,
        //     "page_data": null,
        //     "published_by_useraccount_id": null,
        //     "slug": null,
        //     "subsite": null,
        //     "title": null
        //   }
        // }
        variables: {
          data,
        },
      }),
      transformResponse: (res: any): any => res.createPage,
    }),
    updatePageById: builder.mutation({
      query: ({ id, data: { title, subsite, slug, access, status, page_data, published_by_useraccount_id } }) => ({
        body: gql`
          mutation UpdatePageById($id: ID, $data: PageContent) {
            updatePageById(id: $id, data: $data) {
              _id
              id
            }
          }
        `,
        variables: {
          id,
          data: { title, subsite, slug, access, status, page_data, published_by_useraccount_id },
        },
      }),
      transformResponse: (res: any): any => res.updatePageById,
    }),
    deletePage: builder.mutation({
      query: (id) => ({
        body: gql`
          mutation Mutation($id: ID) {
            deletePageById(id: $id)
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.deletePage,
    }),
    uploadPageImage: builder.mutation({
      query: ({ filename, subsite }) => ({
        body: gql`
          mutation GetUrlUploadPageFile($filename: String!, $subsite: String!) {
            getUrlUploadPageFile(filename: $filename, subsite: $subsite) {
              url
              urlToUpload
            }
          }
        `,
        variables: {
          filename,
          subsite,
        },
      }),
      transformResponse: (res: any): any => res.getUrlUploadPageFile,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFindPageListQuery,
  useLazyFindPageListQuery,
  useFindPageByIdQuery,
  useFindOnePageQuery,
  useLazyFindOnePageQuery,
  useCreatePageMutation,
  useUpdatePageByIdMutation,
  useDeletePageMutation,
  useUploadPageImageMutation,
} = pageBuilderApi;
