import moment from 'moment';

export const getFormattedDate = (dateToFormat?: string, dateformat?: string): string => {
  if (dateToFormat) return `${moment(new Date(dateToFormat ?? '')).format(dateformat ?? 'DD MMM yyyy')}`;
  else return 'N/A';
};

export const transformDatetoMomentStandard = (date?: Date) => {
  return date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    : '';
};

export const getDifferenceInDays = (dateFrom: Date, dateTo: Date): number => {
  return moment(dateFrom).diff(moment(dateTo), 'days');
};

export const getDifferenceInMonths = (dateFrom: Date, dateTo: Date): number => {
  return moment(dateFrom).diff(moment(dateTo), 'months');
};

export const getDifferenceOfDaysPast = (datefrom: Date, monthsago: number): number => {
  const datepast = moment(datefrom).subtract(monthsago, 'months');
  return Math.abs(Math.abs(moment(datefrom).diff(datepast, 'days')));
};

export const getTimeLeft = (dateValue: Date): string => {
  let result = '';
  let difference = getDifferenceInDays(dateValue, new Date());

  if (difference > 0) {
    if (difference > 365) {
      const years = Math.floor(difference / 365);
      difference -= 365 * years;
      result = `${years} year${years > 1 ? 's' : ''}`;
    }
    if (difference > 30) {
      let months = Math.floor(difference / 30);
      // if the result of month is 12, put 11 instead cause of the inconsistency of dividing month to 30
      months = months >= 12 ? 11 : months;
      difference -= 30 * months;
      result = `${result !== '' ? `${result}, ` : ''}${months} month${months > 1 ? 's' : ''}`;
    }

    if (difference > 0) {
      // if the difference more than 31, put 31 instead cause of the inconsistency of dividing month to 30
      result = `${result !== '' ? `${result}, ` : ''}${Math.floor(difference > 30 ? 30 : difference)} day${
        difference > 1 ? 's' : ''
      }`;
    }

    return result;
  } else {
    return 'Expired';
  }
};
