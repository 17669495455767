import { gql } from 'graphql-request';
import { baseApi } from '../../../store/baseApi';

export const subscriptionAndLicenceApi = baseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    //queries
    getSubscriptionAndLicences: builder.query({
      query: (id: string) => ({
        body: gql`
          query FindAllUserSubscriptionAndLicence($userId: ID) {
            findAllUserSubscriptionAndLicence(user_id: $userId) {
              subscriptions {
                id
                activation_date
                expiration_date
                product_snapshot {
                  name
                  is_group
                }
              }
              licences {
                licence_assignee {
                  id
                  email
                  is_institution
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
                user_subscription {
                  product_snapshot {
                    name
                    is_group
                  }
                  activation_date
                  expiration_date
                }
              }
            }
          }
        `,
        variables: {
          userId: id,
        },
      }),
      transformResponse: (res: any) => res.findAllUserSubscriptionAndLicence,
    }),
  }),
});

export const { useLazyGetSubscriptionAndLicencesQuery } = subscriptionAndLicenceApi;
