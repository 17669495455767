import './HexIcon.scss';

interface HexIconProps {
  icon:
    | 'student'
    | 'supervisor'
    | 'subscriber'
    | 'user'
    | 'toga'
    | 'home'
    | 'tag'
    | 'key-outline'
    | 'key'
    | 'bell'
    | 'videolib'
    | 'money'
    | 'logout'
    | 'print'
    | 'email'
    | 'more'
    | 'calendar'
    | 'receipt'
    | 'chevron-up'
    | 'key'
    | 'calendar'
    | 'print'
    | 'chevron-down'
    | 'fa-regular fa-book-open-cover'
    | string
  colorTheme: ColorTheme;
  size?: 'small' | 'medium' | 'regular';
  colorMode?: 'fill' | 'faded' | 'outlined';
  isTextIcon?: boolean; //this is true if inner icon is text
}

export type ColorTheme = 'dark' | 'green' | 'purple' | 'blue' | 'red' | 'yellow';

function HexIcon({ icon, colorTheme, isTextIcon = false, size = 'small', colorMode = 'faded' }: HexIconProps) {
  const getIcon = () => {
    if(['fa-regular fa-book-open-cover'].includes(icon)) return icon;
    else return `icon icon-${icon}`;
  }
  return (
    <div className={`hexagon-wrapper hex-icon-${colorTheme} hex-icon-${size} hex-${colorMode}`}>
      <i className="icon icon-hexagon hex-1" />
      {colorMode === 'outlined' && (
        <>
          <i className="icon icon-hexagon hex-2" />
          <i className="icon icon-hexagon hex-3" />
        </>
      )}
      {isTextIcon ? <div className="inner-icon">{icon}</div> : <i className={`${getIcon()} inner-icon`} />}
    </div>
  );
}

export default HexIcon;
