import config from '../config';

//appends numbers with appropriate suffix
export const nth = (n: number, dontStyleSuffix?: boolean) =>
  `${n}${!dontStyleSuffix ? `<span class='num-suffix'>` : ''}${
    ['st', 'nd', 'rd'][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] || 'th'
  }${!dontStyleSuffix ? `</span>` : ''}`;

//this makes updating of local storage easier
export const updateLocalStorage = (storageKey: string, newItems: any) => {
  //update localstorage
  const prevpageDetails = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey) ?? '{}') : undefined;

  if (prevpageDetails) localStorage.setItem(storageKey, JSON.stringify({ ...prevpageDetails, ...newItems }));
};

export const extractErrorMessage = (formStatus: any): string => {
  try {
    if (formStatus?.isError) {
      if (formStatus?.error?.status === 500) return 'Something went wrong. Please contact administrator.';
      else return formStatus?.error?.data?.response?.errors[0]?.message;
    }
    if (formStatus?.message) {
      const errMessage = formStatus?.message?.substring(0, formStatus?.message.indexOf('.') + 1);
      return errMessage;
    }
  } catch (error) {
    return 'Something went wrong. Please contact administrator.';
  }

  return 'No error found';
};

export const getFormattedPhone = (phone: string): string => {
  const phoneString = phone ? phone.replace(/[^0-9]+/g, '') : phone;
  return phone && phoneString.length >= 10
    ? `${phoneString.substring(0, 4)} ${phoneString.substring(4, 6)} ${phoneString.substring(6, 8)} ${phoneString.substring(
        8,
        10
      )}`
    : phone;
};

export const getAustStateAbbreviations = (state: string) => {
  switch (state) {
    case 'New South Wales':
      return 'NSW';
    case 'Northern Territory':
      return 'NT';
    case 'Queensland':
      return 'Qld';
    case 'South Australia':
      return 'SA';
    case 'Tasmania':
      return 'Tas';
    case 'Victoria':
      return 'Vic';
    case 'Western Australia':
      return 'WA';
    case 'Australian Capital Territory':
      return 'ACT';
    default:
      return state;
  }
};

export const getDecimalDisplay = (numtoDisplay: number) => {
  if (numtoDisplay) {
    const strnum = numtoDisplay.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.');
    return `$${strnum[0]}.<span>${strnum[1] ?? '00'}</span>`;
  } else {
    return `$ 0.<span>00</span>`;
  }
};

export const getFullName = (firstName: string, lastName: string) => {
  if (firstName && lastName) {
    const name =
      firstName[0].toUpperCase() +
      firstName.substr(1, firstName.length) +
      ' ' +
      lastName[0].toUpperCase() +
      lastName.substr(1, lastName.length);
    return name;
  } else {
    return 'No name';
  }
};

//this will round off the number into specific decimal places and return it as number still instead of turning into string
export const toDecimal = (value: number, decimalPlaces: number = 2) => {
  return Number(value.toFixed(decimalPlaces));
};

export const redirectLogin = () => {
  let redirect = location.pathname;
  if (location.search) redirect += location.search;

  window.location.href = `${config.subsitesUrl.main}/login/redirect/${config.subsiteCode}?redirect=${encodeURIComponent(
    redirect
  )}`;
};

export const getLicencesDisplay = (numberOfLicences: number | undefined) => {
  const finalnumber = numberOfLicences ?? 0;
  return `${finalnumber} Licence${finalnumber > 1 ? 's' : ''}`;
};
