import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';

import { baseApi, basePersistentApi } from './baseApi';
import subsiteHexagonReducer from '../features/SubsiteHexagons/store/subsiteHexagonSlice';
import tableReducer from '../features/Table/store/tableSlice';
import stripePaymentReducer from '../features/StripePayment/store/stripePaymentSlice';
import createSubscriptionReducer from '../pages/SubscriptionManagement/CreateSubscription/store/createSubscriptionSlice';
import layoutReducer from '../features/Layout/store/layoutSlice';
import menuHexagonReducer from '../features/MenuHexagons/store/menuHexagonSlice';
import pageBuilderSliceReducer from '../features/PageBuilder/store/pageBuilderSlice';
import profileSliceReducer from '../pages/SubscriptionManagement/MyProfile/store/profileSlice';
import { adminBaseApi } from './adminBaseApi';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [adminBaseApi.reducerPath]: adminBaseApi.reducer,
  [basePersistentApi.reducerPath]: basePersistentApi.reducer,
  subsiteHexagon: subsiteHexagonReducer,
  createSubscription: createSubscriptionReducer,
  table: tableReducer,
  stripePayment: stripePaymentReducer,
  layout: layoutReducer,
  menuHexagon: menuHexagonReducer,
  pageBuilder: pageBuilderSliceReducer,
  profileSlice: profileSliceReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(baseApi.middleware)
        .concat(adminBaseApi.middleware)
        .concat(basePersistentApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
