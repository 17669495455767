import { baseApi } from '../../../store/baseApi';
import { gql } from 'graphql-request';

export const fileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    findFileList: builder.query({
      query: ({ keyword, paginate }) => ({
        body: gql`
          query FindFileList($keyword: String, $paginate: PaginationAggregateOptionsInput) {
            findFileList(keyword: $keyword, paginate: $paginate) {
              docs {
                _id
                filename
                file_url
                access
                updated_at
                uploaded_by {
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
              pagination {
                has_next_page
                has_prev_page
                limit
                next_page
                page
                paging_counter
                prev_page
                total_docs
                total_pages
              }
            }
          }
        `,
        variables: {
          keyword,
          paginate,
        },
      }),
      transformResponse: (res: any): any => res.findFileList,
      keepUnusedDataFor: 0,
    }),
    findFileById: builder.query({
      query: (id) => ({
        body: gql`
          query FindFileById($id: ID!) {
            findFileById(id: $id) {
              id
              filename
              file_url
              access
              created_at
              uploaded_by_useraccount_id
              uploaded_by {
                user_profile {
                  first_name
                  last_name
                }
                institution_profile {
                  institution_name
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.findFileById,
      keepUnusedDataFor: 1,
    }),
    findOneFile: builder.query({
      query: (filter) => ({
        body: gql`
          query FindOneFile($filter: JSON) {
            findOneFile(filter: $filter) {
              id
              filename
              file_url
              access
              created_at
              uploaded_by_useraccount_id
              uploaded_by {
                user_profile {
                  first_name
                  last_name
                }
                institution_profile {
                  institution_name
                }
              }
            }
          }
        `,
        variables: {
          filter,
        },
      }),
      transformResponse: (res: any): any => res.findOneFile,
      keepUnusedDataFor: 1,
    }),

    // mutations
    createFile: builder.mutation({
      query: (data) => ({
        body: gql`
          mutation CreateFile($data: FileContent) {
            createFile(data: $data) {
              file {
                id
                filename
                file_url
                access
                created_at
                uploaded_by_useraccount_id
              }
              uploadUrl
            }
          }
        `,
        // {
        //   "data": {
        //     "filename": null,
        //     "uploaded_by_useraccount_id": null
        //   }
        // }
        variables: {
          data,
        },
      }),
      transformResponse: (res: any): any => res.createFile,
    }),
    updateExistingFileById: builder.mutation({
      query: ({ id, access, subsite }) => ({
        body: gql`
          mutation UpdateExistingFileById($id: ID, $access: String, $subsite: String) {
            updateExistingFileById(id: $id, access: $access, subsite: $subsite) {
              file {
                id
              }
              uploadUrl
            }
          }
        `,
        variables: {
          id,
          access,
          subsite,
        },
      }),
      transformResponse: (res: any): any => res.updateExistingFileById,
    }),
    updateUploadedFileById: builder.mutation({
      query: (id) => ({
        body: gql`
          mutation UpdateUploadedFileById($id: ID) {
            updateUploadedFileById(id: $id) {
              id
              filename
              file_url
              access
              created_at
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (res: any): any => res.updateUploadedFileById,
    }),
    deleteFileById: builder.mutation({
      query: ({ id, subsite }) => ({
        body: gql`
          mutation DeleteFileById($id: ID) {
            deleteFileById(id: $id)
          }
        `,
        variables: {
          id,
          subsite,
        },
      }),
      transformResponse: (res: any): any => res.deleteFileById,
    }),
  }),
});

export const {
  useFindFileListQuery,
  useLazyFindFileListQuery,
  useFindFileByIdQuery,
  useFindOneFileQuery,
  useLazyFindOneFileQuery,
  useCreateFileMutation,
  useUpdateExistingFileByIdMutation,
  useUpdateUploadedFileByIdMutation,
  useDeleteFileByIdMutation,
} = fileApi;
