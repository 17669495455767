export const elementGroupByGrade = [
  {
    id: 'y1',
    term: 1,
    elementGroups: [
      {
        name: 'Sample Group 1',
        description: `<p>Compare the areas of shapes and surfaces (NSW only)</p><p>The Australian Curriculum has no content for Area until Year 4</p><p>The Numeracy Progression V3 / General Capabilities V9 has no content for Area Until Uum3 / Understanding units of measurement P3 Using informal units of measurement in Year 1 and 2</p>`,
        year: 3,
        concept_zone: '123456',
        elements: [
          // {
          //   code: 'G4',
          //   description: '3D OBJECTS, 2D SURFACES, STRAIGHT & CURVED LINES',
          //   color: '#AA2B56',
          //   border_color: '#D596AB',
          // },
          {
            code: 'G4',
            description: 'GIVE AND FOLLOW DIRECTIONS',
            color: '#CC0000',
            border_color: '#D596AB',
          },
        ],
      },
    ],
  },
  {
    id: 'y4',
    term: 2,
    elementGroups: [
      {
        name: 'Sample Group',
        description: `<p>Group with and without group markers, comparing the groups as same (equal) or different, more or less</p><p>Estimate the number in groups</p><p>Collect, sort and compare data in rows</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: 'N11',
            description: 'GROUPING WITH/WITHOUT GROUP MARKERS',
            color: '#00B0F0',
            border_color: '#A8E0F5',
          },
          {
            code: 'N12',
            description: 'ESTIMATE THE NUMBER IN GROUPS',
            color: '#00B0F0',
            border_color: '#A8E0F5',
          },
          {
            code: 'SP 1',
            description: 'Collect, sort and compare data in rows',
            color: '#CC00CC',
            border_color: '#E985E9',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Compare the areas of shapes and surfaces (NSW only)</p><p>The Australian Curriculum has no content for Area until Year 4</p><p>The Numeracy Progression V3 / General Capabilities V9 has no content for Area Until Uum3 / Understanding units of measurement P3 Using informal units of measurement in Year 1 and 2</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: 'G4',
            description: '3D OBJECTS, 2D SURFACES, STRAIGHT & CURVED LINES',
            color: '#AA2B56',
            border_color: '#D596AB',
          },
          {
            code: 'P3',
            description: 'GIVE AND FOLLOW DIRECTIONS',
            color: '#CC0000',
            border_color: '#D596AB',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Three-dimensional objects have 3 dimension (up and down, left to right, front to back), flat surfaces are two-dimensional spaces with straight or curved lines</p><p>Give and follow directions to place, obstacle course, towards, forwards, backwards, turn left, turn right</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: 'M5',
            description: 'COMPARE THE AREAS OF SHAPES AND SURFACES',
            color: '#AA2B56',
            border_color: '#D596AB',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Copy and continue patterns of sounds, actions, objects, shapes and pictures, identifying the part that repeats</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: 'PA1',
            description: 'CONTINUE PATTERS - PART THAT REPEATS',
            color: '#6666FF',
            border_color: '#BABAFF',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Continue investigating and developing understanding of, and metalanguage and capacity to explain counting and number concepts with each student increasing their range</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: '',
            description: 'CONTINUE INVESTIGATING NUMBER & COUNTING CONCEPTS',
            color: '#00B0F0',
            border_color: '#8FE0FD',
          },
        ],
      },
    ],
  },
  {
    id: 'y2',
    term: 3,
    elementGroups: [
      {
        name: 'Sample Group',
        description: `<p>Group with and without group markers, comparing the groups as same (equal) or different, more or less</p><p>Estimate the number in groups</p><p>Collect, sort and compare data in rows</p>`,
        year: 4,
        concept_zone: '123456',
        elements: [
          {
            code: 'N11',
            description: 'Group with and without group markers, comparing the groups as same (equal) or different, more or less',
            color: '#00B0F0',
            border_color: '#A8E0F5',
          },
          {
            code: 'SP 1',
            description: 'Collect, sort and compare data in rows',
            color: '#CC00CC',
            border_color: '#E985E9',
          },
        ],
      },
    ],
  },
  {
    term: 4,
    elementGroups: [],
  },
];

export const elementGroupByConcept = [
  {
    id: 1,
    concept_zone: 'c1',
    name: 'Foundation / Prep / Reception / Kindergarten',
    elementGroups: [
      {
        name: 'Sample Group',
        description: `<p>Group with and without group markers, comparing the groups as same (equal) or different, more or less</p><p>Estimate the number in groups</p><p>Collect, sort and compare data in rows</p>`,
        concept_zone: 'c1',
        elements: [
          {
            code: 'N11',
            description: 'GROUPING WITH/WITHOUT GROUP MARKERS',
            color: '#00B0F0',
            border_color: '#A8E0F5',
          },
          {
            code: 'N12',
            description: 'ESTIMATE THE NUMBER IN GROUPS',
            color: '#00B0F0',
            border_color: '#A8E0F5',
          },
          {
            code: 'SP 1',
            description: 'COLLECT, SORT, COMPARE DATA IN ROWS',
            color: '#CC00CC',
            border_color: '#E985E9',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Compare the areas of shapes and surfaces (NSW only)</p><p>The Australian Curriculum has no content for Area until Year 4</p><p>The Numeracy Progression V3 / General Capabilities V9 has no content for Area Until Uum3 / Understanding units of measurement P3 Using informal units of measurement in Year 1 and 2</p>`,
        concept_zone: 'c1',
        elements: [
          {
            code: 'G4',
            description: '3D OBJECTS, 2D SURFACES, STRAIGHT & CURVED LINES',
            color: '#AA2B56',
            border_color: '#D596AB',
          },
          {
            code: 'P3',
            description: 'GIVE AND FOLLOW DIRECTIONS',
            color: '#CC0000',
            border_color: '#D596AB',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Three-dimensional objects have 3 dimension (up and down, left to right, front to back), flat surfaces are two-dimensional spaces with straight or curved lines</p><p>Give and follow directions to place, obstacle course, towards, forwards, backwards, turn left, turn right</p>`,
        concept_zone: 'c1',
        elements: [
          {
            code: 'M5',
            description: 'COMPARE THE AREAS OF SHAPES AND SURFACES',
            color: '#CC0000',
            border_color: '#D596AB',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Copy and continue patterns of sounds, actions, objects, shapes and pictures, identifying the part that repeats`,
        concept_zone: 'c1',
        elements: [
          {
            code: 'PA1',
            description: 'CONTINUE PATTERS - PART THAT REPEATS',
            color: '#6666FF',
            border_color: '#BABAFF',
          },
        ],
      },
      {
        name: 'Sample Group',
        description: `<p>Continue investigating and developing understanding of, and metalanguage and capacity to explain counting and number concepts with each student increasing their range</p>`,
        concept_zone: 'c1',
        elements: [
          {
            code: '',
            description: 'CONTINUE INVESTIGATING NUMBER & COUNTING CONCEPTS',
            color: '#00B0F0',
            border_color: '#8FE0FD',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Year 1',
    elementGroups: [],
  },
  {
    id: 3,
    name: 'Year 2',
  },
  {
    id: 4,
    name: 'Year 3',
  },
  {
    id: 5,
    name: 'Year 4',
  },
  {
    id: 6,
    name: 'Year 5',
  },
  {
    id: 7,
    name: 'Year 6',
  },
];

export const ultimatePageAccordions = [
  {
    id: 1,
    name: 'Explicit Teaching Resources',
    header_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    color: '#2B8080',
    main_color: '#96C0C0',
    secondary_color: '#E6F0F0',
    resource_items: [
      {
        id: 1,
        code: 'COMPLETE N11, N12 & SP11',
        description: '',
        color: '#FAF2ED',
        border_color: '#CE8147',
      },
      {
        id: 2,
        code: 'N11',
        description: 'GROUPING WITH/WITHOUT GROUP MARKERS',
        color: '#00B0F0',
        border_color: '#A7E7FF',
        default_color: '#ffffff',
      },
      {
        id: 3,
        code: 'N12',
        description: 'ESTIMATE THE NUMBER IN GROUPS',
        color: '#00B0F0',
        border_color: '#A7E7FF',
        default_color: '#ffffff',
      },
      {
        id: 4,
        code: 'SP 1',
        description: 'COLLECT, SORT, COMPARE DATA IN ROWS',
        color: '#CC00CC',
        border_color: '#E9B3E9',
        default_color: '#ffffff',
      },
    ],
  },
  {
    id: 2,
    name: 'Explicit Teaching Videos',
    header_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    color: '#F89A3C',
    main_color: '',
    secondary_color: '',
    is_video: true,
    resource_items: [
      {
        id: 1,
        title: 'AS Concept Sequence 1',
        src: 'https://player.vimeo.com/video/400147649?h=92e37679cc',
      },
      {
        id: 2,
        title: 'AS Concept Sequence 2',
        src: 'https://player.vimeo.com/video/400147649?h=92e37679cc',
      },
      {
        id: 3,
        title: 'AS Concept Sequence 3',
        src: 'https://player.vimeo.com/video/400147649?h=92e37679cc',
      },
      {
        id: 4,
        title: 'AS Concept Sequence 4',
        src: 'https://player.vimeo.com/video/400147649?h=92e37679cc',
      },
    ],
  },
  {
    id: 3,
    name: 'Explicit Teaching Powerpoint',
    header_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    color: '#AE2D77',
    main_color: '',
    secondary_color: '',
    resource_items: [
      {
        id: 1,
        code: 'PV37 (i)',
        description: 'ORDER TWO DIGIT NUMBERS',
        color: '#FCF6F9',
        border_color: '#AE2D77',
      },
      {
        id: 2,
        code: 'PV37 (ii)',
        description: 'ORDER TWO DIGIT NUMBERS',
        color: '#FCF6F9',
        border_color: '#AE2D77',
      },
      {
        id: 3,
        code: 'PV37 (iii)',
        description: 'ORDER TWO DIGIT NUMBERS',
        color: '#FCF6F9',
        border_color: '#AE2D77',
      },
    ],
  },
  {
    id: 4,
    name: 'Explicit Problem Solving',
    header_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    color: '#017E86',
    main_color: '',
    secondary_color: '',
    resource_items: [
      {
        id: 1,
        code: 'PV37 (i)',
        description: 'ORDER TWO DIGIT NUMBERS',
        color: '#E6F8F9',
        border_color: '#017E86',
      },
      {
        id: 2,
        code: 'PV37 (i)',
        description: 'ORDER TWO DIGIT NUMBERS',
        color: '#E6F8F9',
        border_color: '#017E86',
      },
    ],
  },
  {
    id: 5,
    name: 'Mathematical Missions',
    header_desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    color: '#AA2B56',
    main_color: '',
    secondary_color: '',
    resource_items: [
      {
        id: 1,
        code: 'COMPLETE MATHEMATICAL MISSIONS',
        description: '',
        color: '#E6F0F0',
        border_color: '#2B8080',
      },
      {
        id: 2,
        code: 'PART 1 MATHEMATICAL MISSIONS',
        description: '',
        color: '#FEF5EC',
        border_color: '#F89A3C',
      },
      {
        id: 3,
        code: 'PART 2 MATHEMATICAL MISSIONS',
        description: '',
        color: '#F5E6EB',
        border_color: '#AA2B56',
      },
      {
        id: 4,
        code: 'PART 3 MATHEMATICAL MISSIONS',
        description: '',
        color: '#F2E6F2',
        border_color: '#912B91',
      },
      {
        id: 5,
        code: 'PART 4 MATHEMATICAL MISSIONS',
        description: '',
        color: '#EDEEFC',
        border_color: '#4950DF',
      },
      {
        id: 6,
        code: 'PART 5 MATHEMATICAL MISSIONS',
        color: '#EAF9F9',
        border_color: '#017E86',
      },
    ],
  },
];

export const ultimatePageElements = [
  {
    id: 'y1',
    term: 1,
    name: 'Sample Group 1',
    description: `<p>Group with and without group markers, comparing the groups as same (equal) or different, more or less</p><p>Estimate the number in groups</p><p>Collect, sort and compare data in rows</p>`,
    year: 3,
    concept_zone: '123456',
    elements: [
      {
        code: 'N11',
        description: 'GROUPING WITH/WITHOUT GROUP MARKERS',
        color: '#00B0F0',
        border_color: '#A8E0F5',
      },
      {
        code: 'N12',
        description: 'ESTIMATE THE NUMBER IN GROUPS',
        color: '#00B0F0',
        border_color: '#A8E0F5',
      },
      {
        code: 'SP 1',
        description: 'COLLECT, SORT, COMPARE DATA IN ROWS',
        color: '#CC00CC',
        border_color: '#E985E9',
      },
    ],
  },
];

export const TRElementGroup = [
  {
    id: 1,
    name: 'EXPLICIT TEACHING RESOURCES',
    color: '#E6F0F0',
    border_color: '#2B8080',
  },
  {
    id: 2,
    name: 'EXPLICIT TEACHING VIDEOS',
    color: '#FEF5EC',
    border_color: '#F89A3C',
  },
  {
    id: 3,
    name: 'EXPLICIT TEACHING POWERPOINT',
    color: '#FCF6F9',
    border_color: '#AE2D77',
  },
  {
    id: 4,
    name: 'EXPLICIT PROBLEM SOLVING',
    color: '#E6F8F9',
    border_color: '#3ecbcb',
  },
  // {
  //   id: 5,
  //   name: 'MATHEMATICAL MISSIONS',
  //   color: '#F5E6EB',
  //   border_color: '#AA2B56',
  // },
  // {
  //   id: 6,
  //   name: 'MIND MY METACOGNITION',
  //   color: '#EDEEFC',
  //   border_color: '#4950DF',
  // },
];

export const TRZone = [
  {
    id: 1,
    name: 'ZONE INVESTIGATIONS',
    color: '#FAF2ED',
    border_color: '#CE8147',
  },
  {
    id: 2,
    name: 'ZONE PROBLEMS',
    color: '#E6F8F9',
    border_color: '#017E86',
  },
  {
    id: 3,
    name: 'ZONE ANCHOR CHARTS FOR DIFFERENTIATION, INTERVENTION, INTEGRATION',
    color: '#F2E6F2',
    border_color: '#912B91',
  },
  {
    id: 4,
    name: 'ZONE MANUAL',
    color: '#FCF6F9',
    border_color: '#AE2D77',
  },
  {
    id: 5,
    name: 'ZONE MATHEMATICAL MISSIONS',
    color: '#EDEEFC',
    border_color: '#4950DF',
  },
];

export const ZonePageModules = [
  {
    id: 1,
    title: 'Game Investigations',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit.',
    color: 'green',
  },
  {
    id: 2,
    title: 'Mathematical Talk Investigation',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit.',
    color: 'yellow-text',
  },
];

export const ExplicitTeachingResource = [
  {
    id: 1,
    files: [],
  },
  {
    id: 2,
    files: [],
  },
  {
    id: 3,
    files: [],
  },
  {
    id: 4,
    files: [],
  },
  {
    id: 5,
    files: [],
  },
];

export const boxColor = new Map();
boxColor.set(0, 'red');
boxColor.set(1, 'yellow');
boxColor.set(2, 'purple');
boxColor.set(3, 'blue');

export const darkBoxColor = new Map();
darkBoxColor.set(0, 'dark-red');
darkBoxColor.set(1, 'dark-yellow');
darkBoxColor.set(2, 'dark-purple');
darkBoxColor.set(3, 'dark-blue');

export const boxColor2 = new Map();
boxColor2.set(0, 'red');
boxColor2.set(1, 'purple');
boxColor2.set(2, 'blue');
boxColor2.set(3, 'green');
boxColor2.set(4, 'yellow');

export const darkBoxColor2 = new Map();
darkBoxColor2.set(0, 'dark-red');
darkBoxColor2.set(1, 'dark-purple');
darkBoxColor2.set(2, 'dark-blue');
darkBoxColor2.set(3, 'dark-green');
darkBoxColor2.set(4, 'dark-yellow');

export const hexMenuColor = new Map();
hexMenuColor.set(0, 'red');
hexMenuColor.set(1, 'yellow');
hexMenuColor.set(2, 'purple');
hexMenuColor.set(3, 'blue');
hexMenuColor.set(4, 'yellow');

export const hexMenuDarkColor = new Map();
hexMenuDarkColor.set(0, 'dark-red');
hexMenuDarkColor.set(1, 'dark-purple');
hexMenuDarkColor.set(2, 'dark-blue');
hexMenuDarkColor.set(3, 'dark-green');
hexMenuDarkColor.set(4, 'dark-yellow');

export const boxColorDesc = new Map();
boxColorDesc.set(1, 'green');
boxColorDesc.set(2, 'yellow');
boxColorDesc.set(3, 'red');
boxColorDesc.set(4, 'brown');
boxColorDesc.set(5, 'blue');
boxColorDesc.set(6, 'lightblue');
boxColorDesc.set(7, 'purple');

export const mmBoxColor = new Map();
mmBoxColor.set(1, '#F89A3C');
mmBoxColor.set(2, '#AA2B56');
mmBoxColor.set(3, '#912B91');
mmBoxColor.set(4, '#4950DF');
mmBoxColor.set(5, '#017E86');

export const mmBgBoxColor = new Map();
mmBgBoxColor.set(1, '#FEF5EC');
mmBgBoxColor.set(2, '#F5E6EB');
mmBgBoxColor.set(3, '#F2E6F2');
mmBgBoxColor.set(4, '#EDEEFC');
mmBgBoxColor.set(5, '#EAF9F9');
