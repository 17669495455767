import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ImageProps {
  src: string;
  placeholder?: string;
  alt?: string;
  className?: string;
  blurry?: boolean;
  width?: number;
  height?: number;
}

function Image({ src, placeholder, alt, className, blurry, width, height }: ImageProps) {
  return (
    <LazyLoadImage
      src={src}
      placeholderSrc={placeholder}
      alt={alt}
      width={width}
      height={height}
      className={className}
      effect={blurry ? 'blur' : undefined}
    />
  );
}

export default Image;
