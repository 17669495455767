import { useMemo } from 'react';
import './Layout.scss';
import Footer from './Footer/Footer';
import Navigation from './Navigations/Navigation';
import SideNavigation from './SideNavigation/SideNavigation';
import Background from './Background/Background';
import ResourceFilter from './ResourceFilter/ResourceFilter';
import SubscriptionFilter from './SubscriptionFilter/SubscriptionFilter';
import useLayout from './hooks/useLayout';
import cookie from '@helpers/cookieHelper';

interface LayoutProps {
  children: JSX.Element;
}

function Layout({ children }: LayoutProps) {
  const { isSideNavigationVisible, isSidemenuIconView, showResourceFilter, checked } = useLayout();

  const resouceFilterClass = useMemo(() => {
    if (showResourceFilter && cookie.isLoggedIn()) {
      return 'resource-filter-visible-authenticated';
    }

    return 'resource-filter-visible';
  }, [showResourceFilter, cookie]);

  return (
    <div>
      <Navigation is_subscribed={checked} />
      <main className="flex flex-row">
        {cookie?.isLoggedIn() ? <SideNavigation is_subscribed={checked} /> : <></>}
        {isSideNavigationVisible ? showResourceFilter ? <ResourceFilter /> : <SubscriptionFilter /> : <></>}

        <section
          className={`right-content flex flex-column${isSideNavigationVisible ? ' sidemenu-visible' : ''}${
            isSidemenuIconView ? ' sidemenu-icon-view' : ''
            // }${showResourceFilter ? '' : ''}`}
          } ${resouceFilterClass}`}
        >
          <div className="page-content flex flex-column">
            <Background />
            {children}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
