/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import HexIcon from '../../../components/CustomIcons/HexIcon/HexIcon';
import { NavLink, useLocation } from 'react-router-dom';
import './SubscriptionFilter.scss';
import useLayout from '../hooks/useLayout';
import { sidemenuItem } from '../Utils/constants';
import { useLogoutMutation } from '../../../store/services/authApi';
import { ColorTheme } from '@components/CustomIcons/HexIconReverse/HexIcon';
function SubscriptionFilter() {
  const { isSideNavigationVisible, isSidemenuIconView } = useLayout();
  const location = useLocation();
  const [logout, formStatus] = useLogoutMutation();
  const [collapsibleMenus, setCollapsibleMenus] = useState<any[]>([]);
  const [selectedSubMenu, setSelectedSubMenu] = useState('subscription/tr');
  const [showSubMenu, setShowSubMenu] = useState(true);

  useEffect(() => {
    //check if the current URL is a collapsible menu or under a collapsible menu
    const pathname = location.pathname.replace('/', '');
    const collapsible = collapsibleMenus.find(
      (m: any) =>
        m.urlPath === pathname ||
        m.submenu?.find((x: any) => x.urlPath === pathname || x.chainUrls?.some((item: any) => item === pathname))
    );

    let updatedMenus: any[];
    if (collapsible) {
      updatedMenus = collapsibleMenus.map((menu) =>
        menu.urlPath === pathname ||
        menu.submenu?.find((x: any) => x.urlPath === pathname || x.chainUrls?.some((item: any) => item === pathname))
          ? {
              ...collapsible,
              isCollapsed: true,
            }
          : {
              ...menu,
              isCollapsed: false,
            }
      );
    } else {
      updatedMenus = collapsibleMenus.map((menu) => {
        return {
          ...menu,
          isCollapsed: false,
        };
      });
    }

    setCollapsibleMenus(updatedMenus);
  }, [location.pathname, sidemenuItem]);

  const getNestedMenu = (menu: any): JSX.Element | null => {
    const collapsible = collapsibleMenus.find((x: any) => x.displayName === menu.displayName);

    if (collapsible === undefined || collapsible === null || (collapsible && !collapsible.isCollapsed)) {
      //if not yet added, add it to the state
      if (collapsible === undefined || collapsible === null) {
        setCollapsibleMenus([...collapsibleMenus, menu]);
      }
      return null;
    }

    return (
      <li className="sub-menu flex flex-row">
        <div className="vertical-divider">
          <div />
        </div>
        <div className="w-100">
          <ul>
            {menu.submenu?.map((item: any, key: number) => (
              <li key={key}>
                <NavLink
                  to={item.urlPath}
                  className={({ isActive }) =>
                    isActive || item.chainUrls?.some((x: any) => x === location.pathname.replace('/', ''))
                      ? 'sidemenu-selected'
                      : ''
                  }
                  end
                >
                  <span>{item.displayName}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  };

  const getMenu = (): JSX.Element[] => {
    return sidemenuItem[0].items.map((item, key) => {
      return (
        <React.Fragment key={key}>
          <li className="relative">
            <NavLink
              to={item.urlPath}
              onClick={() => {
                if (selectedSubMenu === item.urlPath) {
                  setShowSubMenu(!showSubMenu);
                } else {
                  setShowSubMenu(true);
                  setSelectedSubMenu(item.urlPath);
                }
              }}
              className={({ isActive }) =>
                `${item.color}-themed-box ${isActive || (item.submenu && item.submenu.length > 0) ? 'sidemenu-selected' : ''} ${
                  !item.submenu || (item.submenu && item.submenu.length == 0)
                    ? isActive
                      ? 'non-collapsible-selected'
                      : ''
                    : 'has-submenu'
                }`
              }
              end
            >
              <>
                <HexIcon icon={item.icon} colorTheme={item.color as ColorTheme} colorMode="fill" />
                <span>{item.displayName}</span>

                {item.submenu && item.submenu.length > 0 ? (
                  selectedSubMenu === item.urlPath && showSubMenu ? (
                    <i className="icon icon-chevron-up collapse-menu-icon" />
                  ) : (
                    <i className="icon icon-chevron-down collapse-menu-icon" />
                  )
                ) : (
                  <></>
                )}
              </>
            </NavLink>
          </li>

          {selectedSubMenu === item.urlPath &&
            showSubMenu &&
            item.submenu &&
            !isSidemenuIconView &&
            item.submenu.length > 0 &&
            getNestedMenu(item)}
        </React.Fragment>
      );
    });
  };

  const triggerLogout = () => {
    logout('');
  };

  return (
    <aside className={`${isSideNavigationVisible ? 'sidemenu-visible ' : ''}${isSidemenuIconView ? 'sidemenu-icon-view' : ''}`}>
      <section className="menu-row">
        <ul className="flex flex-col">{getMenu()}</ul>
      </section>

      <div className="row-divider" />

      <section className="menu-row">
        <ul>
          <li onClick={triggerLogout}>
            <NavLink to="/subscription" end>
              <HexIcon icon="logout" colorTheme="green" />
              <span>Logout</span>
            </NavLink>
          </li>
        </ul>
      </section>
    </aside>
  );
}

export default SubscriptionFilter;
