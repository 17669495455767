import { createSlice } from '@reduxjs/toolkit';

export interface StripePaymentProps {
  secret: string;
  btnText: string;
  activity: 'create' | 'addlicense';
  extraFailedParams?: string; //in case you wanna add more params to failed url
  withMarginTop?: boolean;
}

const initialState: StripePaymentProps = {
  secret: '',
  btnText: 'Proceed Payment',
  activity: 'create',
  extraFailedParams: '',
  withMarginTop: false,
};

export const stripePaymentSlice = createSlice({
  name: 'stripePayment',
  initialState,
  reducers: {
    setStripeProps: {
      reducer: (state, action: any) => {
        state.secret = action.payload.secret;
        state.btnText = action.payload.btnText;
        state.activity = action.payload.activity;
        state.extraFailedParams = action.payload.extraFailedParams ?? '';
        state.withMarginTop = action.payload.withMarginTop ?? false;
      },
      prepare: (secret: string, activity: string, btnText: string, extraFailedParams?: string, withMarginTop?: boolean): any => {
        return {
          payload: {
            secret,
            btnText,
            activity,
            extraFailedParams,
            withMarginTop,
          },
        };
      },
    },
  },
});

export default stripePaymentSlice.reducer;

export const { setStripeProps } = stripePaymentSlice.actions;
