import { useNode } from '@craftjs/core';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const usePanelStyles = makeStyles()({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-expanded': {
      margin: '0 0',
      minHeight: '40px',
      '&:before': {
        opacity: '1',
      },
      '& + .MuiExpansionPanel-root:before ': {
        display: 'block',
      },
    },
  },
});

const useSummaryStyles = makeStyles()({
  root: {
    minHeight: '36px',
    padding: 0,
  },
  content: {
    margin: '0px',
  },
});

function ToolbarSection({ title, props, summary, children }: any) {
  const { classes: panelClasses } = usePanelStyles();
  const { classes: summaryClasses } = useSummaryStyles();
  const { nodeProps } = useNode((node) => ({
    nodeProps:
      props &&
      props.reduce((res: any, key: any) => {
        res[key] = node.data.props[key] || null;

        return res;
      }, {}),
  }));

  return (
    <Accordion classes={panelClasses}>
      <AccordionSummary classes={summaryClasses}>
        <div className="tw-w-full tw-px-6">
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <h5 className="tw-text-dary-gray tw-text-left tw-text-sm tw-font-medium tw-text-light-gray-1">{title}</h5>
            </Grid>
            {summary && props ? (
              <Grid item xs={8}>
                <h5 className="tw-text-dark-blue tw-text-right tw-text-sm tw-text-light-gray-2">
                  {summary(
                    props.reduce((acc: any, key: any) => {
                      acc[key] = nodeProps[key];

                      return acc;
                    }, {})
                  )}
                </h5>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px 24px 20px' }}>
        <Divider />
        <Grid container spacing={1}>
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ToolbarSection;
