import { createSlice } from '@reduxjs/toolkit';
import subsites from '../../../config/subsites';

export type OrientationType = 'inline' | 'compact';
interface SubsiteHexagonProps {
  selectedSubsiteCode: string;
  selectedSubsiteName: string;
  isSelectionMode: boolean;
  orientation: OrientationType;
}

const initialState: SubsiteHexagonProps = {
  selectedSubsiteCode: '',
  selectedSubsiteName: '',
  isSelectionMode: false,
  orientation: 'compact',
};

export const subsiteHexagonSlice = createSlice({
  name: 'subsiteHexagon',
  initialState,
  reducers: {
    resetSelection: (state) => {
      state.selectedSubsiteCode = initialState.selectedSubsiteCode;
      state.selectedSubsiteName = initialState.selectedSubsiteName;
      state.isSelectionMode = initialState.isSelectionMode;
      state.orientation = initialState.orientation;
    },
    setSelectedSubsite: {
      reducer: (state, action: any) => {
        state.selectedSubsiteCode = action.payload.selectedSubsiteCode;
        state.selectedSubsiteName =
          subsites.find((sub: any) => sub.short_code === action.payload.selectedSubsiteCode)?.name ?? '';
      },
      prepare: (selectedSubsiteCode: string): any => {
        return {
          payload: {
            selectedSubsiteCode,
          },
        };
      },
    },
    setSelectionMode: {
      reducer: (state, action: any) => {
        state.isSelectionMode = action.payload.isSelectionMode;
      },
      prepare: (isSelectionMode: boolean): any => {
        return {
          payload: {
            isSelectionMode,
          },
        };
      },
    },
    setOrientation: {
      reducer: (state, action: any) => {
        state.orientation = action.payload.orientation;
      },
      prepare: (orientation: OrientationType): any => {
        return {
          payload: {
            orientation,
          },
        };
      },
    },
  },
});

export default subsiteHexagonSlice.reducer;

export const { resetSelection, setSelectedSubsite, setSelectionMode, setOrientation } = subsiteHexagonSlice.actions;
