import { useLazyGlobalSearchQuery } from "@store/searchResourceApi";
import { useEffect, useState } from "react";

function useResourceSearchInput() {
    const [searchItems, { data: searchResult, isLoading: searchLoading, isFetching: searchFetching }] = useLazyGlobalSearchQuery();
    const [result, setResult] = useState<any[]>([]);

    const search = async (keyword: string) => {
      await searchItems({
        keyword
      })
    }

    useEffect(() =>  {
      if(searchResult)
        setResult(searchResult as any[]);
    },[searchResult])
    
    return {
      search,
      result,
      searchLoading,
      searchFetching,
    };
  }
  
  export default useResourceSearchInput;
  